import { useCallback } from "react";
import type { Container, Engine, ISourceOptions } from "tsparticles-engine";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

import { Stack } from "@mui/material";
import { options } from "./utils";
import logo from "../../assets/bn.png";

const Index = () => {
  const particlesInit = useCallback(async (engine: Engine) => {
    // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {},
    []
  );

  return (
    <div
      style={{
        position: "relative",
        userSelect: "none", // Disable text selection
        overflow: "hidden", // Prevent scrolling
        height: "100vh", // Set the container height to the viewport height
        cursor: "help",
      }}
    >
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{ ...(options as ISourceOptions) }}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />

      <Stack
        direction={"column"}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)", // Center the stack
          zIndex: 999,
          color: "white",
          p: 10,
        }}
        display={"grid"}
        justifyContent={"center"}
        spacing={2}
      >
        <img
          src={logo}
          alt="beyondnoticed_logo"
          style={{
            maxWidth: "20rem",
            pointerEvents: "none",
            zIndex: -50,
          }}
        />
        <div
          style={{
            marginTop: "-0.1rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: "0.8rem" }}>
            floating in the void, unnoticed by most,
          </p>
          <p style={{ fontSize: "0.8rem" }}>but seen by the highest...</p>
        </div>

        {/* <Button
          sx={{
            "@media (max-width:415px)": {
              fontSize: "0.8rem", // Adjust size for smaller screens
            },
           border: "1px solid white",
            backgroundColor: "black",
            color: "white",
            fontWeight: "bold",
          }}
          target="_blank"
          href="http://shop.beyondnoticed.com/"
        >
          ENTER...

        </Button> */}
      </Stack>
    </div>
  );
};

export default Index;
